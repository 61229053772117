import React, { useRef, useEffect } from 'react'
import { Location } from '@reach/router'
import posed, { PoseGroup } from 'react-pose'
import styled from '@emotion/styled'

import { Power4 } from '../lib/gsap'
import { useTimeline } from '../hooks/animation'
import { media, rem } from '../styles/tools'

import Meta from '../components/Meta'
import Link from '../components/Link'
import Transition from '../components/Transition'
import { WideLink } from '../styles/typography'
import Logo from '../components/logos/AnthonyWiktor'

const Navbar = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  position: 'fixed',
  zIndex: 10,
  top: 0,
  left: 0,
  padding: rem(20),
  [media.tablet]: {
    padding: `${rem(30)} ${rem(30)}`,
  },
  mixBlendMode: 'difference',
  willChange: 'transform',
})

const PosedLinkWrapper = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 20, opacity: 0 },
})

const Navigation = () => {
  const tl = useTimeline({ delay: 0.6 })

  useEffect(() => {
    tl.to(nav.current, 0.8, {
      opacity: 1,
      ease: Power4.easeOut,
    })
  }, [])

  const nav = useRef()

  return (
    <nav ref={nav} css={{ marginLeft: 'auto', opacity: 0 }}>
      <Location>
        {({ location }) => (
          <PoseGroup>
            {location.pathname === '/' ? (
              <PosedLinkWrapper key="/about/">
                <WideLink as={Link} to="/about/">
                  About
                </WideLink>
              </PosedLinkWrapper>
            ) : (
              <PosedLinkWrapper key="/">
                <WideLink as={Link} to="/">
                  Portfolio
                </WideLink>
              </PosedLinkWrapper>
            )}
          </PoseGroup>
        )}
      </Location>
    </nav>
  )
}

const Layout = ({ children, location }) => {
  return (
    <div>
      <Meta />
      <Navbar>
        <Link to="/" css={{ '&, svg': { display: 'block' } }}>
          <Logo play css={{ width: 200, [media.tablet]: { width: 250 } }} />
        </Link>
        <Navigation />
      </Navbar>
      <Transition location={location}>{children}</Transition>
    </div>
  )
}

export default Layout

const fontPreloadHeaders = [
  'Link: </fonts/sequel-100-wide-95.woff2>; rel=preload; as=font; crossorigin',
  'Link: </fonts/TungstenMedium.woff>; rel=preload; as=font; crossorigin',
  'Link: </fonts/SourceSerifProRegular.woff2>; rel=preload; as=font; crossorigin',
]

const fontFaces = [
  {
    '@font-face': {
      fontFamily: 'Sequel',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontDisplay: 'block',
      src: 'url("/fonts/sequel-100-wide-95.woff2") format("woff2")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'TungstenMedium',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontDisplay: 'block',
      src: 'url("/fonts/TungstenMedium.woff") format("woff")',
    },
  },
  {
    '@font-face': {
      fontFamily: 'SourceSerifPro',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontDisplay: 'fallback',
      src: 'url("/fonts/SourceSerifProRegular.woff2") format("woff2")',
    },
  },
]

module.exports = {
  fontPreloadHeaders,
  fontFaces,
}

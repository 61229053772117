import React from 'react'
import styled from '@emotion/styled'
import { media, min, rem } from './tools'

export const wideText = {
  fontWeight: 'normal',
  fontSize: rem(14),
  lineHeight: 1,
  fontFamily: 'TungstenMedium, sans-serif',
  letterSpacing: '0.5em',
  textTransform: 'uppercase',
}

export const boldText = {
  fontWeight: 800,
  lineHeight: 1,
  fontFamily: 'Sequel, sans-serif',
  textTransform: 'uppercase',
}

const boldTextSmall = {
  fontSize: rem(14),
  [media.tabletMd]: { fontSize: rem(16) },
}

const boldTextMedium = {
  fontSize: '5vw',
  [media.tabletLg]: { fontSize: '2.4vw' },
  [min(1525)]: { fontSize: rem(40) },
}

const boldTextLarge = {
  fontSize: '8vw',
  [media.tabletLg]: { fontSize: '4.2vw' },
  [min(1525)]: { fontSize: rem(70) },
}

export const SocialLink = styled.a(
  boldText,
  ({ small }) => (small ? boldTextSmall : boldTextMedium),
  {
    transition: 'color .3s',
    ':hover': { color: 'var(--color)' },
    '::after': {
      content: '"."',
      color: 'var(--color)',
    },
  }
)

export const Headline = styled.p(boldText, boldTextLarge, { lineHeight: 0.9 })

Headline.StrikeThrough = styled.s({
  display: 'inline-block',
  position: 'relative',
  textDecoration: 'none',

  '&::after': {
    content: '""',
    display: 'block',
    width: 'calc(100% + .3em)',
    height: '0.25em',
    position: 'absolute',
    top: '0.37em',
    left: '-0.14em',
    backgroundColor: 'currentColor',
    transform: 'scaleX(0)',
    transformOrigin: 'center left',
    transitionDuration: '1s',
    transitionProperty: 'transform, opacity',
    transitionTimingFunction: 'cubic-bezier(0.55, 0, 0.1, 1)',
  },

  '&.is-crossed::after': {
    transform: 'scaleX(1)',
  },

  '&:hover::after': {
    transform: 'scaleY(0)',
    opacity: 0,
  },
})

export const SerifText = styled.p({
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: rem(18),
  lineHeight: 1.8,
  fontFamily: 'SourceSerifPro, serif',
  [media.tabletMd]: {
    fontSize: rem(21),
  },
})

SerifText.Strong = styled.strong(boldText, { fontSize: '78%' })

export const BoldTextSmall = styled.span(boldText, boldTextSmall)

export const BoldTextMedium = styled.span(boldText, boldTextMedium)

export const Underline = styled.span({
  position: 'relative',
  '&::after': {
    content: '""',
    display: 'block',
    width: '100%',
    height: '0.35em',
    position: 'absolute',
    top: '70%',
    left: 0,
    backgroundColor: 'currentColor',
    opacity: 0.4,
    transitionDuration: '.3s',
    transitionProperty: 'transform, opacity',
    transitionTimingFunction: 'cubic-bezier(0.55, 0, 0.1, 1)',
  },

  '&:hover::after': {
    transform: 'scaleY(1.3)',
    opacity: 0.5,
  },
})

export const WideText = styled.span(wideText)

export const WideTextUnderlined = styled.span(wideText, {
  position: 'relative',
  '&::after': {
    content: '""',
    display: 'block',
    width: 'calc(100% - 0.5em)',
    height: '0.35em',
    position: 'absolute',
    top: '70%',
    left: 0,
    backgroundColor: 'currentColor',
    opacity: 0.4,
    transitionDuration: '.3s',
    transitionProperty: 'transform, opacity',
    transitionTimingFunction: 'cubic-bezier(0.55, 0, 0.1, 1)',
  },

  '&:hover::after': {
    transform: 'scaleY(1.3)',
    opacity: 0.5,
  },
})

export const WideLink = styled.a(wideText, {
  display: 'inline-block',
  position: 'relative',
  zIndex: 0,

  '&::after': {
    content: "''",
    display: 'block',
    width: 'calc(100% - 0.5em)',
    height: '40%',
    position: 'absolute',
    zIndex: -1,
    top: '60%',
    left: 0,
    backgroundColor: '#444',
    transform: 'scaleX(0)',
    transformOrigin: 'center right',
    transition: 'transform .6s cubic-bezier(0.55, 0, 0.1, 1)',
  },

  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'center left',
  },
})

export const SectionTitle = ({
  as: As = 'span',
  viewBox,
  d,
  height = '2.65ex',
  children,
  ...props
}) => (
  <span
    css={{
      display: 'inline-block',
      position: 'relative',
      margin: '0 0 -0.44em -0.45em',
      ...boldTextLarge,
    }}
    {...props}
  >
    <As
      css={{
        position: 'absolute',
        zIndex: 1,
        top: '-10%',
        left: '0.6%',
        fontWeight: 800,
        fontSize: '178%',
        lineHeight: 0.9,
        textTransform: 'uppercase',
        color: 'transparent',
      }}
    >
      {children}
    </As>
    <svg
      viewBox={viewBox}
      css={{
        display: 'block',
        height,
        fill: 'none',
        strokeWidth: 1.5,
        stroke: 'currentColor',
      }}
    >
      <path d={d} />
    </svg>
  </span>
)

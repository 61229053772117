import theme from './theme'
import { globalSelection } from './tools'
import { fontFaces } from './fonts'

export default [
  ...fontFaces,
  {
    '*, *:before, *:after': {
      boxSizing: 'border-box',
    },

    ...globalSelection('rgba(200, 200, 200, 0.99)', theme.colors.black),

    html: {
      fontSize: 16,
      fontFamily: 'Sequel, sans-serif',
      textSizeAdjust: '100%',
      overflowX: 'hidden',
    },

    body: {
      color: '#fff',
      backgroundColor: theme.colors.black,
      transition: 'background-color .75s cubic-bezier(.8, 0, .2, 1)',
    },

    'body, figure, h1, h2, h3, h4, h5, h6, p, ol, ul, blockquote': {
      margin: 0,
      padding: 0,
      fontWeight: 'normal',
    },

    'ol li, ul li': {
      listStyle: 'none',
    },

    a: {
      textDecoration: 'none',
      color: 'inherit',
    },

    button: {
      margin: 0,
      padding: 0,
      font: 'inherit',
      color: 'inherit',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      backgroundColor: 'transparent',
    },

    img: {
      display: 'block',
      maxWidth: '100%',
    },
  },
]
